@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

#root {
	overflow-x: hidden;
}

#centered-div {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

#get-started {
	background: linear-gradient(to right, #ffffff, #668cff, #001a66) !important;

}

#purple-gradient {
	background: linear-gradient(to right, #ffffff, #cc66ff, #cc66ff) !important;
}

#light-blue {
	background: linear-gradient(to right, #DDD0F9, #DDD0F9, #180D33) !important;
}

#start {
	background: linear-gradient(to right, #632ff3, #8731af, #cc66ff) !important;
}

#today {
	background: linear-gradient(to right, #632ff3, #8731af, #cc66ff) !important;
}

#end-ing {
	background: linear-gradient(to right, #cc66ff, #1316e7) !important;
}

#unit {
	background: linear-gradient(to right, #a15bac, #baa6e7, #7b8fdd) !important;
}

#offices {
	background: linear-gradient(to right, #d896e2, #a6b3e7, #7b8fdd) !important;
}

#get-started,
#purple-gradient,
#light-blue,
#start,
#today,
#offices,
#end-ing,
#unit {
	-webkit-background-clip: text !important;
	background-clip: text !important;
	color: transparent !important;
	color: transparent;
}

#end-ing, #today {
	display: flex;
	justify-content: center;
	align-items: center;
}